<template>
<div class="my-5 text-center">

  <app-signin />


  <button v-if="stepN==0" @click="nextFunction" type="button" class="mt-3  btn btn-success btn-lg font-weight-bold"  style="direction:ltr!important; " >
    <i class="whatsapp icon"></i> طلب رمز التحقق </button>
  <!--
  <button v-if="stepN==1" type="submit" class="mt-3 form-control btn   bwj-btn btn-lg" name="submit" style="width:12rem" @click="nextFunction">تسجيل الدخول</button>

<button v-if="stepN==2" type="submit" class="mt-3 form-control btn   bwj-btn btn-lg" name="submit" style="width:12rem" @click="nextFunction">إعادة ارسال الرمز</button>
-->
</div>
</template>

<script>
import SignIn from "../components/auth/signin.vue";

export default {

  components: {
    'app-signin': SignIn,


  },
  methods: {

    nextFunction() {

      console.log(this.$children[0]);

      this.$children[0].submmit();
    
    

    }
  },
  computed: {
    stepN() {
      return this.$store.state.glopal.loginStepN;
    }
  }
}
</script>
